import { Inject, Injectable, isDevMode } from '@angular/core';

import environment from '@configuration/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private name!: string;

  constructor(@Inject('name') name: string) {
    this.name = name.replace('_', '');
  }

  log(message: string, ...args: unknown[]): void {
    console.log(`[${this.name}] ${message}\n`, ...args);
  }

  error(message: string, ...args: unknown[]): void {
    console.error(`[${this.name}] ${message}\n`, ...args);
  }

  warn(message: string, ...args: unknown[]): void {
    console.warn(`[${this.name}] ${message}\n`, ...args);
  }

  info(message: string, ...args: unknown[]): void {
    console.info(`[${this.name}] ${message}\n`, ...args);
  }

  debug(message: string, ...args: unknown[]): void {
    if (environment.debug || !isDevMode())
      console.debug(`[${this.name}] ${message}\n`, ...args);
  }

  /*
  private callerName(): string {
    try {
      throw new Error();
    } catch (e) {
      // Get the stack trace
      const stack = e.stack;
      if (stack) {
        // Find the first line that doesn't contain the LoggerService class name
        const lines = stack.split('\n');
        for (let i = 0; i < lines.length; i++) {
          if (!lines[i].includes('LoggerService')) {
            // Extract the caller name from the line
            const callerLine = lines[i].trim();
            const callerName = callerLine.substring(
              callerLine.indexOf('at ') + 3,
              callerLine.indexOf('(')
            );
            return callerName;
          }
        }
      }
    }
    return 'Unknown';
  }
  */
}

import SessionDTO from './session.dto';
import { HardwareInfo } from '@models/hardware-info.class';
import { Session } from '@models/session.class';
import { SystemInfo } from '@models/system-info.class';

export class SessionMapper {
  static fromDTO(dto: SessionDTO): Session {
    return new Session({
      // id: dto.idSession,
      active: dto.active === 1,
      version: dto.appVersion,
      device: new HardwareInfo({
        id: dto.deviceId,
        model: dto.deviceModel,
      }),
      system: new SystemInfo({
        osVersion: dto.systemVersion,
      }),
      createdAt: new Date(dto.createdAt),
      lastActive: new Date(dto.lastActive),
    });
  }

  /*
  static toDTO(session: Session): SessionDTO {
    return {
      registered: session.registered,
      expiration: session.expiration?.toISOString(),
    };
  }
  */
}

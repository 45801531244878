import {
  computed,
  effect,
  Injectable,
  Injector,
  isDevMode,
  signal,
} from '@angular/core';
import * as FCM from '@angular/fire/messaging';

import {
  BehaviorSubject,
  catchError,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { configuration } from '@configuration/configuration';

import { CookiesService } from '@services/cookies/cookies.service';
import { LoggerService } from '@services/logger/logger.service';
import { NotificationsService } from '@services/notifications/notifications.service';

export const FCM_TAG = 'FCM_TOKEN_ZONETACTS';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  private logger = new LoggerService(this.constructor.name);

  private message$ = new BehaviorSubject<unknown | undefined>(undefined);

  private fcmMessaging!: FCM.Messaging;
  private unsubscribe$?: FCM.Unsubscribe;
  private sw$ = signal<ServiceWorkerRegistration | undefined>(undefined);

  private enabled$ = computed(
    () =>
      configuration.integrations?.firebase?.cloudMessaging &&
      this.notifications.supported$()
  );

  // Lifecycle hooks
  constructor(
    private notifications: NotificationsService,
    private cookiesService: CookiesService,
    private injector: Injector
  ) {
    effect(() => {
      if (this.enabled$())
        from(FCM.isSupported())
          .pipe(
            tap((supported) =>
              console.log('[Firebase Cloud Messaging] Supported:', supported)
            ),
            filter((supported) => supported === true),
            tap(() => {
              this.fcmMessaging = this.injector.get(FCM.Messaging);
              if (isDevMode())
                console.log(
                  '[Firebase Cloud Messaging] Messaging instance:',
                  this.fcmMessaging
                );
            }),
            switchMap(() => this.initialize())
          )
          .subscribe();
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) this.unsubscribe$();
  }

  // Methods
  initialize(
    { url, scope }: { url: string; scope: string } = {
      // ? Default Firebase Cloud Messaging service worker URL
      url: 'firebase-messaging-sw.js',
      scope: 'firebase-cloud-messaging-push-scope',
    }
  ): Observable<void> {
    // ? Request permission to send notifications
    return from(this.notifications.request()).pipe(
      // ? Proceed if the permission is granted
      filter((granted) => granted === 'granted'),
      switchMap(() =>
        from(navigator.serviceWorker.getRegistrations()).pipe(
          switchMap((serviceWorkers) => {
            const serviceWorker = serviceWorkers.find(
              (sw) => sw.active?.scriptURL === url
            );
            // ? If the app is installed as a PWA, Angular will already have registered the service worker in the background (there can be only one service worker per app & scope)
            if (serviceWorker) {
              console.log(
                '[Firebase Cloud Messaging] Service worker already registered',
                serviceWorker
              );
              return of(serviceWorker);
            }
            // ? Otherwise, and in case the firebase-cloud-messaging.js service worker is not registered yet, we need to register it
            else {
              console.log(
                '[Firebase Cloud Messaging] Registering service worker...'
              );
              return from(
                navigator.serviceWorker.register(url, {
                  scope: scope,
                })
              ).pipe(
                tap((serviceWorker) => {
                  if (!serviceWorker)
                    throw new Error(
                      `[Firebase Cloud Messaging] Service worker not found in route ${url}`
                    );
                  else if (isDevMode())
                    console.log(
                      '[Firebase Cloud Messaging] Service worker registered',
                      serviceWorker
                    );
                }),
                catchError((error) => {
                  console.error(
                    '[Firebase Cloud Messaging] An error occurred while registering the service worker',
                    error
                  );
                  throw error;
                })
              );
            }
          }),
          tap((serviceWorker) => this.sw$.set(serviceWorker)),
          // ? Start listening for messages if a token is available
          switchMap(() =>
            this.cookiesService.getById(FCM_TAG).pipe(
              filter((token) => !!token),
              map(() => this.listen()),
              catchError(() => of(undefined))
            )
          )
        )
      )
    );
  }

  token(): Observable<string> {
    if (this.enabled$())
      return from(
        FCM.getToken(this.fcmMessaging, {
          serviceWorkerRegistration: this.sw$(),
        })
      ).pipe(
        tap((token) => {
          console.log('[Firebase Cloud Messaging] Token:', token);
          this.cookiesService.putById(FCM_TAG, token).subscribe();
        }),
        catchError((error) => {
          console.warn(
            '[Firebase Cloud Messaging] An error occurred while getting the token',
            error
          );
          throw error;
        })
      );
    return of('');
  }

  listen() {
    if (this.enabled$()) {
      console.log('[Firebase Cloud Messaging] Listening for messages...');
      this.unsubscribe$ = FCM.onMessage(this.fcmMessaging, {
        next: (payload) => {
          console.warn('[Firebase Cloud Messaging] Message received:', payload);
          this.logger.log(
            '[Firebase Cloud Messaging] Message received:\n',
            payload
          );
          this.message$.next(payload);
        },
        error: (error) => {
          console.error('[Firebase Cloud Messaging] Message error', error);
        },
        complete: () => {
          console.log('[Firebase Cloud Messaging] Message complete');
        },
      });
    }
  }

  deleteToken(): Observable<boolean> {
    if (!this.enabled$()) return of(false);
    return from(FCM.deleteToken(this.fcmMessaging)).pipe(
      tap(() => {
        console.log('[Firebase Cloud Messaging] Token deleted');
        this.cookiesService.removeById(FCM_TAG).subscribe();
      }),
      catchError((error) => {
        console.error(
          '[Firebase Cloud Messaging] An error occurred while deleting the token',
          error
        );
        throw error;
      })
    );
  }

  regenerateToken(): Observable<string> {
    return this.deleteToken().pipe(
      filter((deleted) => deleted && !!this.sw$()),
      switchMap(() => this.token())
    );
  }
}

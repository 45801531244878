import { computed, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { of } from 'rxjs';

import { NetworkService } from '@services/network/network.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private connected$ = computed(() => this.networkService.connected$());

  constructor(
    private router: Router,
    private readonly networkService: NetworkService,
    private readonly tokenService: AuthTokenService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.tokenService.token$();
    const expiration = this.tokenService.expiration$();

    if (!token) {
      // ? No token, redirect to login
      this.router.navigate(['/auth/login']);
      return of(false);
    } else if (!this.connected$()) {
      // ? No network, but token, allow access (offline mode)
      return of(true);
    } else if (!expiration || expiration < new Date()) {
      // ? Token expired, redirect to login
      this.router.navigate(['/auth/login']);
      return of(false);
    }
    return of(true);
  }
}

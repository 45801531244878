import { HardwareInfo } from './hardware-info.class';
import { SystemInfo } from './system-info.class';

interface ISession {
  // id: number;
  active: boolean;
  device: HardwareInfo;
  system: SystemInfo;
  version: string;
  createdAt: Date;
  lastActive: Date;
}

export class Session implements ISession {
  // readonly id!: number;
  readonly active!: boolean;
  readonly device!: HardwareInfo;
  readonly system!: SystemInfo;
  readonly version!: string;
  readonly createdAt!: Date;
  readonly lastActive!: Date;

  constructor(session: ISession) {
    Object.assign(this, session);
  }
}

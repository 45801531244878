{
  "name": "zonetacts-webapp",
  "version": "3.0.0",
  "private": true,
  "engines": {
    "node": ">=20 <21",
    "npm": ">=10 <11"
  },
  "scripts": {
    "tauri": "tauri",
    "vite": "vite",
    "ng": "ng",
    "dev": "ng serve chatprise --configuration development",
    "dev:local": "ng serve chatprise --configuration development,local",
    "vite:dev": "vite serve",
    "start:pwa:chatprise": "ng serve chatprise --configuration production",
    "start:app:chatprise": "npm run tauri dev -- --config src/assets/clients/chatprise/tauri.conf.json",
    "start:ios:chatprise": "npm run tauri ios dev -- --config src/assets/clients/chatprise/tauri.conf.json",
    "build:pwa:chatprise": "ng build chatprise",
    "build:app:chatprise": "npm run tauri build -- --config src/assets/clients/chatprise/tauri.conf.json",
    "build:ios:chatprise": "npm run tauri ios build -- --config src/assets/clients/chatprise/tauri.conf.json",
    "start:pwa:emcesa": "ng serve emcesa --configuration production",
    "start:app:emcesa": "npm run tauri dev -- --config src/assets/clients/emcesa/tauri.conf.json",
    "build:pwa:emcesa": "ng build emcesa",
    "build:app:emcesa": "npm run tauri build -- --config src/assets/clients/emcesa/tauri.conf.json",
    "start:pwa:icatel": "ng serve icatel --configuration production",
    "start:app:icatel": "npm run tauri dev -- --config src/assets/clients/icatel/tauri.conf.json",
    "build:pwa:icatel": "ng build icatel",
    "build:app:icatel": "npm run tauri build -- --config src/assets/clients/icatel/tauri.conf.json",
    "start:pwa:levantina": "ng serve levantina --configuration production",
    "start:app:levantina": "npm run tauri dev -- --config src/assets/clients/levantina/tauri.conf.json",
    "build:pwa:levantina": "ng build levantina",
    "build:app:levantina": "npm run tauri build -- --config src/assets/clients/levantina/tauri.conf.json",
    "start:pwa:mutua": "ng serve mutua --configuration production",
    "start:app:mutua": "npm run tauri dev -- --config src/assets/clients/mutua/tauri.conf.json",
    "build:pwa:mutua": "ng build mutua",
    "build:app:mutua": "npm run tauri build -- --config src/assets/clients/mutua/tauri.conf.json",
    "start:pwa:uoc": "ng serve uoc --configuration production",
    "start:app:uoc": "npm run tauri dev -- --config src/assets/clients/uoc/tauri.conf.json",
    "build:pwa:uoc": "ng build uoc",
    "build:app:uoc": "npm run tauri build -- --config src/assets/clients/uoc/tauri.conf.json",
    "start:pwa:xategem": "ng serve xategem --configuration production",
    "start:app:xategem": "npm run tauri dev -- --config src/assets/clients/xategem/tauri.conf.json",
    "build:pwa:xategem": "ng build xategem",
    "build:app:xategem": "npm run tauri build -- --config src/assets/clients/xategem/tauri.conf.json",
    "test": "ng test",
    "transpile:sw": "rimraf ./src/pwa/service-workers/dist/*.js && tsc --project ./src/pwa/service-workers/tsconfig.json",
    "lint:angular": "ng lint",
    "lint": "eslint \"{src,test}/**/*.{html,ts}\"",
    "lint:fix": "eslint \"{src,test}/**/*.{html,ts}\" --fix",
    "format": "prettier --check \"{src,test}/**/*.{html,scss,ts,json}\"",
    "format:fix": "prettier --write \"{src,test}/**/*.{html,scss,ts,json}\"",
    "compodoc:build": "compodoc -p tsconfig.doc.json",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s",
    "compodoc:serve": "compodoc -s"
  },
  "dependencies": {
    "@angular/animations": "^18.2.7",
    "@angular/cdk": "^18.2.7",
    "@angular/cdk-experimental": "^18.2.7",
    "@angular/common": "^18.2.7",
    "@angular/compiler": "^18.2.7",
    "@angular/core": "^18.2.7",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.2.7",
    "@angular/platform-browser": "^18.2.7",
    "@angular/platform-browser-dynamic": "^18.2.7",
    "@angular/router": "^18.2.7",
    "@angular/service-worker": "^18.2.7",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@kolkov/angular-editor": "^3.0.0-beta.0",
    "@ng-icons/core": "^25.1.0",
    "@ng-icons/lucide": "^26.3.0",
    "@ng-select/ng-select": "^13.9.0",
    "@ngneat/overview": "^6.1.1",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/router-store": "^18.0.2",
    "@ngrx/signals": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@ngxpert/hot-toast": "^3.0.2",
    "@spartan-ng/ui-accordion-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-alertdialog-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-avatar-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-checkbox-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-collapsible-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-command-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-core": "^0.0.1-alpha.352",
    "@spartan-ng/ui-dialog-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-formfield-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-forms-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-hovercard-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-label-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-menu-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-popover-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-progress-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-radiogroup-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-select-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-separator-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-sheet-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-switch-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-table-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-tabs-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-toggle-brain": "0.0.1-alpha.356",
    "@spartan-ng/ui-tooltip-brain": "0.0.1-alpha.356",
    "@tauri-apps/cli": "^2.0.1",
    "bs-stepper": "^1.7.0",
    "class-transformer": "^0.5.1",
    "class-variance-authority": "^0.6.0",
    "clsx": "^2.1.1",
    "embla-carousel-angular": "^14.0.0",
    "flag-icons": "^7.2.3",
    "flowbite": "^2.5.2",
    "highlight.js": "^11.10.0",
    "mime": "^4.0.4",
    "ng2-pdf-viewer": "^10.3.3",
    "ngx-cookie-service": "^18.0.0",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-scrollbar": "^13.0.1",
    "ngx-sonner": "^2.0.0",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.0",
    "shepherd.js": "^13.0.3",
    "socket.io-client": "^2.5.0",
    "sweetalert2": "^11.14.1",
    "tailwindcss-displaymodes": "^1.0.8",
    "tslib": "^2.7.0",
    "uuid": "^10.0.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.7",
    "@angular-eslint/builder": "18.3.1",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cli": "~18.2.7",
    "@angular/compiler-cli": "^18.2.7",
    "@babel/generator": "^7.25.7",
    "@babel/parser": "^7.25.7",
    "@babel/traverse": "^7.25.7",
    "@compodoc/compodoc": "^1.1.25",
    "@eslint/compat": "^1.2.0",
    "@ngrx/eslint-plugin": "^18.0.2",
    "@spartan-ng/cli": "^0.0.1-alpha.351",
    "@types/jasmine": "~4.3.0",
    "@types/serviceworker": "^0.0.85",
    "@types/socket.io-client": "^1.4.36",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "^8.8.0",
    "@typescript-eslint/parser": "^8.8.0",
    "@typescript-eslint/utils": "^8.8.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.12.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.6.3",
    "eslint-plugin-html": "^8.1.2",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-unused-imports": "^4.1.4",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.47",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.8",
    "tailwind-merge": "^2.2.0",
    "tailwindcss": "^3.4.13",
    "tailwindcss-animate": "^1.0.6",
    "typescript": "~5.4.5"
  },
  "volta": {
    "node": "18.20.4",
    "npm": "10.8.2"
  },
  "packageManager": "pnpm@9.12.0+sha512.4abf725084d7bcbafbd728bfc7bee61f2f791f977fd87542b3579dcb23504d170d46337945e4c66485cd12d588a0c0e570ed9c477e7ccdd8507cf05f3f92eaca"
}

<picture class="h-full">
  <!-- Preferably, use the logo -->
  @if (content() == 'logo' && configuration.ui.header?.preferLogo) {
    <source
      type="image/svg+xml"
      srcset="assets/images/branding/logo/logo.svg"
    />
    <source type="image/png" srcset="assets/images/branding/logo/logo.png" />
    <source type="image/jpeg" srcset="assets/images/branding/logo/logo.jpg" />
  }
  <!-- If no logo is found, use the icon -->
  <source type="image/svg+xml" srcset="assets/icon/icon.svg" />
  <source type="image/png" srcset="assets/icon/icon.png" />
  <source type="image/jpeg" srcset="assets/icon/icon.jpg" />
  <img
    src="assets/icon/icon.jpg"
    [alt]="configuration.company + ' logo'"
    class="h-full"
  />
</picture>
<!--
  ! Without image optimization
<img
  [srcset]="[
    'assets/icon/icon.svg',
    'assets/icon/icon.png',
    'assets/icon/icon.jpg'
  ]"
  src="assets/icon/icon.jpg"
  [alt]="configuration.company + ' logo'"
/>
-->

<!--
! Image optimization with ngSrc - Fixed size mode
<img
  priority
  [srcset]="[
    'assets/icon/icon.svg',
    'assets/icon/icon.png',
    'assets/icon/icon.jpg'
  ]"
  ngSrc="assets/icon/icon.png"
  class="mr-3"
  [alt]="configuration.company + ' logo'"
  width="32"
  height="32"
/>
-->

<!--
! Image optimization with ngSrc - Fill mode
<div class="relative mr-3 h-8">
  <img
    [srcset]="[
      'assets/icon/icon.svg',
      'assets/icon/icon.png',
      'assets/icon/icon.jpg'
    ]"
    ngSrc="assets/icon/icon.png"
    [alt]="configuration.company + ' logo'"
    priority
    fill
  />
</div>
-->
@if (name() && configuration.ui.header?.showCompanyName) {
  <span class="self-center truncate text-2xl font-semibold dark:text-white">
    {{ configuration.company }}</span
  >
}

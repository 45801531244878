interface ISystemInfo {
  os: string;
  osVersion: string;
  browser: string;
  browserVersion: string;
}

export class SystemInfo implements ISystemInfo {
  readonly os!: string;
  readonly osVersion!: string;
  readonly browser!: string;
  readonly browserVersion!: string;

  constructor(info?: Partial<ISystemInfo>) {
    Object.assign(this, {
      os: info?.os || '',
      osVersion: info?.osVersion || '',
      browser: info?.browser || '',
      browserVersion: info?.browserVersion || '',
    });
  }
}

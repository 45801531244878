import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { asyncScheduler, forkJoin, observeOn, tap } from 'rxjs';

import { AuthStore } from '@state/stores/auth.store';
import { DiscoversStore } from '@state/stores/discovers.store';
import { MessagesStore } from '@state/stores/messages.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';
import { UIStore } from '@state/stores/ui.store';
import { UsersStore } from '@state/stores/users.store';

import { DiscoversService } from '@services/api/discovers/discovers.service';
import { MessagesService } from '@services/api/messages/messages.service';
import { OTOsService } from '@services/api/otos/otos.service';
import { ProfileService } from '@services/api/profile/profile.service';
import { GenericRoomsService } from '@services/api/rooms/generic-rooms.service';
import { RoomsService } from '@services/api/rooms/rooms.service';
import { UsersService } from '@services/api/users/users.service';
import { CookiesService } from '@services/cookies/cookies.service';
import { FcmService } from '@services/firebase/cloud-messaging/fcm.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';
import { RegistrationTokenService } from '@services/tokens/registration-token.service';

import { ZTSocket } from '@sockets/zonetacts/zonetacts.socket';

@Injectable({ providedIn: 'root' })
export class AppService {
  constructor(
    private readonly router: Router,
    private readonly socket: ZTSocket,

    private readonly profileService: ProfileService,
    private readonly usersService: UsersService,
    private readonly otosService: OTOsService,
    private readonly roomsService: RoomsService,
    private readonly discoversService: DiscoversService,
    private readonly genericRoomsService: GenericRoomsService,
    private readonly messagesService: MessagesService,

    private readonly authTokenService: AuthTokenService,
    private readonly registrationTokenService: RegistrationTokenService,
    private readonly fcmService: FcmService,
    private readonly cookiesServce: CookiesService,

    private readonly authStore: AuthStore,
    private readonly userStore: UsersStore,
    private readonly otosStore: OTOsStore,
    private readonly roomsStore: RoomsStore,
    private readonly discoversStore: DiscoversStore,
    private readonly messagesStore: MessagesStore,
    private readonly uiStore: UIStore
  ) {}

  check(): void {
    console.debug('Checking...');
    // TODO: This should validate our current URL and app state based on the token, and redirect to the correct page if needed
  }

  reset(): void {
    this.socket.connect();

    this.authTokenService.removeToken();
    this.registrationTokenService.removeToken();
    this.fcmService.deleteToken().pipe(observeOn(asyncScheduler)).subscribe();

    this.authStore.reset();
    this.userStore.reset();
    this.otosStore.reset();
    this.roomsStore.reset();
    this.discoversStore.reset();
    this.messagesStore.reset();
    this.uiStore.reset();
  }

  load(date?: Date) {
    if (date) this.changes(date);
    else this.everything();
  }

  private everything() {
    if (isDevMode()) console.debug('Loading data...');

    forkJoin([
      this.cookiesServce
        .getById('TENANT')
        .pipe(tap((tenant) => this.socket.connect(tenant))),
      this.profileService.loadProfile(),
      this.usersService.getUsers(),
      // this.otosService.getOTOs(),
      this.genericRoomsService.getAllOTOs(),
      // this.roomsService.getRooms(),
      this.genericRoomsService.getAllRooms(),
      this.discoversService.getDiscovers(),
      this.messagesService.getLatestMessages(10),
    ])
      .pipe(observeOn(asyncScheduler))
      .subscribe();
  }

  private changes(date: Date) {
    if (isDevMode()) console.debug('Loading changes since', date);

    forkJoin([
      this.cookiesServce
        .getById('TENANT')
        .pipe(tap((tenant) => this.socket.connect(tenant))),
      this.profileService.loadProfile(),
      this.usersService.getUsers(),
      // this.otosService
      this.roomsService.getRooms({ date }),
      this.discoversService.getDiscovers({ date }),
    ])
      .pipe(observeOn(asyncScheduler))
      .subscribe();
  }
}

import { HttpClient } from '@angular/common/http';
import { computed, Injectable } from '@angular/core';

import { map, Observable, tap } from 'rxjs';

import { configuration } from '@configuration/configuration';

import LoadProfileResponseDTO from './models/load-profile.response.dto';
import NotificationPreferences from './models/notification-preferences.interface';
import { User } from '@models/user.class';

import { AuthStore } from '@state/stores/auth.store';
import { UsersStore } from '@state/stores/users.store';

import { CookiesService } from '@services/cookies/cookies.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private url: string;

  private me$ = this.authStore.me;
  private profile$ = computed(() =>
    this.me$() ? this.usersStore.get(this.me$()!) : undefined
  );

  constructor(
    private http: HttpClient,
    private readonly authStore: AuthStore,
    private readonly usersStore: UsersStore,
    private cookiesService: CookiesService
  ) {
    this.url = configuration.apiUrl;
  }

  loadProfile(): Observable<User> {
    return this.http.get<LoadProfileResponseDTO>(`${this.url}/users/me`).pipe(
      tap((response) =>
        this.cookiesService
          .putById('TENANT', response.idClient.toString())
          .subscribe()
      ),
      tap((response) => this.authStore.set('me', response.idUser)),
      map(
        (response) =>
          new User({
            ...response,
            id: response.idUser,
            name: response.name,
            surname: response.surname,
            email: response.emailContact ?? undefined,
            bio: response.additionalInfo,
          })
      )
    );
  }

  // TODO: Add/Update an endpoint to update the user's profile with a partial user object, instead of having 3 different endpoints
  updateProfile(user: Partial<User>): Observable<void> {
    if (!this.me$()) throw new Error('User not authenticated');

    const formData = new FormData();
    if (user.name) formData.append('name', user.name);
    if (user.surname) formData.append('surname', user.surname);
    if (user.email) formData.append('emailContact', user.email);
    if (user.bio) formData.append('additionalInfo', user.bio);
    if (user.picture) formData.append('file', user.picture);

    return this.http
      .put<void>(`${this.url}/users/me/modifydata`, formData)
      .pipe(
        tap(() => {
          this.usersStore.update(this.me$()!, () => ({ ...user }));
        })
      );
  }

  /*
  updatePicture(picture: Blob): Observable<Blob> {
    const formData = new FormData();
    formData.append('file', picture);

    return this.http
      .put<void>(`${this.url}/users/me/imageprofile`, formData)
      .pipe(map(() => picture));
  }
  */

  updateEmailPreferences(
    preferences: Partial<NotificationPreferences>
  ): Observable<void> {
    const formData = new FormData();
    if (preferences.email) {
      formData.append('emailContact', preferences.email);
    }
    if (preferences.enabled) {
      formData.append('emailNotifications', preferences.enabled ? '1' : '0');
    }

    return this.http.put<void>(`${this.url}/users/me/modifydata`, formData);
  }
}

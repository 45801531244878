interface IHardwareInfo {
  id?: string;
  model: string;
  manufacturer: string;
  cpu: string;
  architecture: string;
}

export class HardwareInfo implements IHardwareInfo {
  readonly id?: string;
  readonly model!: string;
  readonly manufacturer!: string;
  readonly cpu!: string;
  readonly architecture!: string;

  constructor(info?: Partial<IHardwareInfo>) {
    Object.assign(this, {
      id: info?.id,
      model: info?.model || '',
      manufacturer: info?.manufacturer || '',
      cpu: info?.cpu || '',
      architecture: info?.architecture || '',
    });
  }
}

import { Injectable, signal } from '@angular/core';

import { catchError, map, of, take } from 'rxjs';

import { SameSite } from 'ngx-cookie-service';

import { CookiesService } from '@services/cookies/cookies.service';

const TOKEN_KEY = 'registration-token';

// TODO: Use the private state token API to get the token from other ZT trusted domains
// ? https://developers.google.com/privacy-sandbox/protections/private-state-tokens?hl=es-419

@Injectable({
  providedIn: 'root',
})
export class RegistrationTokenService {
  // Properties
  private options: {
    expiration?: Date | number;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: SameSite;
  };
  token$ = signal<string | undefined>(undefined);
  expiration$ = signal<Date | undefined>(undefined);

  // Lifecycle hooks
  constructor(private cookiesStorage: CookiesService) {
    this.options = {
      secure: true,
      domain: window.location.hostname,
      path: '/', // ? This ensures that the cookie is available in all routes
      sameSite: 'Lax',
    };

    // Set the service's token and expiration subjects from the cookie values
    this.cookiesStorage
      .getById(TOKEN_KEY)
      .pipe(
        take(1),
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((token) => {
        this.token$.set(token);
      });

    this.cookiesStorage
      .getById(TOKEN_KEY + '-expiration')
      .pipe(
        take(1),
        map((expiration) => (expiration ? new Date(expiration) : undefined)),
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((expiration) => {
        this.expiration$.set(expiration);
      });
  }

  get token(): string | undefined {
    return this.token$();
  }

  set token(token: string | undefined) {
    if (!token || token.length === 0) this.removeToken();
    else this.cookiesStorage.putById(TOKEN_KEY, token, this.options);
    this.token$.set(token);
  }

  get expiration(): Date | undefined {
    return this.expiration$();
  }

  set expiration(expiration: Date | undefined) {
    if (!expiration)
      this.cookiesStorage.removeById(TOKEN_KEY + '-expiration').subscribe();
    else
      this.cookiesStorage.putById(
        TOKEN_KEY + '-expiration',
        expiration.toISOString(),
        this.options
      );
    this.expiration$.set(expiration);
  }

  removeToken(): void {
    this.cookiesStorage.removeById(TOKEN_KEY, this.options).subscribe();
    this.cookiesStorage
      .removeById(TOKEN_KEY + '-expiration', this.options)
      .subscribe();
    this.token$.set(undefined);
    this.expiration$.set(undefined);
  }
}

export interface IAppError {
  status: number;
  code: number;
  detail: { [language: string]: string };
}

export class AppError {
  status!: number;
  code!: string;
  detail!: { [language: string]: string };

  constructor(error?: IAppError | string) {
    if (typeof error === 'string') {
      const err = JSON.parse(error);
      this.status = err.error.status;
      this.detail = err.error.detail;
      this.code = err.error.code;
    } else {
      Object.assign(this, error);
    }
  }
}

export function instanceOfAppError(o?: any): o is AppError {
  return (
    'status' in o &&
    'code' in o &&
    'detail' in o &&
    typeof o.detail === 'object'
  );
}

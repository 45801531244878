import { Component, HostBinding, input } from '@angular/core';

import { configuration } from '@configuration/configuration';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  // Host bindings
  @HostBinding('class') class = 'flex items-center';

  // Inputs & Outputs
  content = input<'logo' | 'icon'>('logo');
  name = input<boolean>(false);

  // Properties
  configuration = configuration;
}

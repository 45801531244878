import { Injectable } from '@angular/core';

import { switchMap, tap } from 'rxjs';

import { v4 as uuid } from 'uuid';

import { CookiesService } from '@services/cookies/cookies.service';

export const DEVICE_ID_TAG = 'DEVICE_ID';
@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private id: string = 'Unknown';

  constructor(private cookiesService: CookiesService) {
    // ? Generate a new device id if it does not exist
    this.cookiesService
      .existsById(DEVICE_ID_TAG)
      .pipe(
        switchMap((exists) => {
          if (!exists) {
            this.id = uuid();
            return this.cookiesService.putById(DEVICE_ID_TAG, this.id);
          }
          return this.cookiesService
            .getById(DEVICE_ID_TAG)
            .pipe(tap((id) => (this.id = id)));
        })
      )
      .subscribe();
  }

  get deviceId(): string {
    return this.id;
  }

  get model(): string {
    return 'Unknown';
  }

  get os(): string {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      windows = ['Win32', 'Win64', 'Windows', 'WinCE'],
      macOS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      linux = ['Linux', 'X11'],
      android = ['Android'],
      iOS = ['iPhone', 'iPod'],
      iPadOS = ['iPad'];

    switch (true) {
      case /iPad/.test(userAgent):
        return 'iPadOS';
      case /iPhone/.test(userAgent):
        return 'iOS';
      case /Android/.test(userAgent):
        return 'Android';
      case /Mac/.test(platform):
        return 'MacOS';
      case /Win/.test(platform):
        return 'Windows';
      case /Linux/.test(platform):
        return 'Linux';
      default:
        return 'Unknown OS';
    }
  }

  get browser() {
    return {
      agent: window.navigator.userAgent,
      name: this.browserName(),
      version: this.browserVersion(),
    };
  }

  private browserName(): string {
    const userAgent = window.navigator.userAgent,
      browsers = [
        'Chrome',
        'Firefox',
        'Safari',
        'Opera',
        'MSIE',
        'Trident',
        'Edge',
      ];

    for (const browser of browsers) {
      if (new RegExp(browser, 'i').test(userAgent)) {
        return browser;
      }
    }
    return 'Unknown';
  }

  private browserVersion() {
    const userAgent = window.navigator.userAgent,
      browsers = [
        'Chrome',
        'Firefox',
        'Safari',
        'Opera',
        'MSIE',
        'Trident',
        'Edge',
      ];

    for (const browser of browsers) {
      const match = new RegExp(`${browser}/([\\d.]+)`, 'i').exec(userAgent);
      if (match) {
        return match[1];
      }
    }

    return 'Unknown';
  }
}
